.searchBar {
  --background: #fff;
  border: 1px solid #f9f2fc;
  border-radius: 30px;
}

@media (min-width: 601px) {
  .searchBar {
    border-radius: 4px;
  }
}
