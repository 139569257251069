.title {
  font-size: clamp(24px, 2.223vw, 32px);
  font-weight: 700;
  line-height: 0.75;
  color: #33213d;
  margin: 0;
}

.body,
.email,
.resendText {
  font-size: clamp(14px, 1.1115vw, 16px);
  line-height: 1.4375;
}

.body {
  font-weight: 400;
  color: #81668f;
  margin-block: 1em;
}

.email {
  font-weight: 500;
  color: #7614ac;
  margin: 0;
  margin-block: 0 2.5em;
}

.mailLink {
  display: block;
  width: 100% !important;
  text-decoration: none !important;
}

.resendWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 28px 0;
}

.resendText {
  font-weight: 400;
  text-align: center;
  color: #81668f;
  margin: 0;
}

.resendLink {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857;
  text-align: center;
  color: #7614ac;
  margin: 0;
  cursor: pointer;
}
