.scanAddress {
  width: 120px;
  margin-block: 16px;
}

.scanAddressWrapper {
  display: flex;
  justify-content: center;
}

.cryptoHeaderText {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  text-align: center;
  color: #81668f;
  margin: 0 0 55px 0;
}

.heading {
  font-size: 14px;
  color: #33213d;
  font-weight: 400;
  margin: 0 0 5px 0;
}

.adderessWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;
}

.networkHeadingWrapper {
  margin-top: 15px;
}

.adderess {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  color: #b1a3b8;
  margin: 0;
}

.copy {
  width: 24px;
  flex-shrink: 0;
}
