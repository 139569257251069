.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.overlay {
  position: absolute;
  top: 75%;
  right: 0;
  bottom: -5%;
  left: 0;
  background: linear-gradient(
    180.4deg,
    rgba(255, 255, 255, 0) 0.4%,
    #ffffff 52.24%
  );
}

.bigImageWrapper {
  width: 100%;
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  max-height: 30vh;
}

.bigImage {
  width: 100%;
  max-width: 140px;
}

.bigImageTwo {
  width: 100%;
  max-width: 255px;
}

.title {
  flex-shrink: 0;
  width: 100%;
  font-size: 18px;
  color: #33213d;
  font-weight: 700;
  line-height: 1.22;
  margin: 0;
  text-align: center;
}

.text {
  width: 90%;
  max-width: 320px;
  margin-block: 16px 0;
  text-align: center;
  color: #81668f;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 16px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

@media (min-width: 650px) {
  .bodyWrapper {
    flex-direction: row-reverse;
    justify-content: center;
    /* gap: 100px; */
    width: 80%;
    flex-grow: 1;
    width: 100%;
  }

  .bigImageWrapper {
    margin-bottom: 0;
    width: 35% !important;
    flex-grow: 0;
    overflow: hidden;
    max-height: 70vh;
    flex-shrink: 3;
  }

  .textWrapper {
    min-width: 350px;
    align-self: center;
    width: 65% !important;
    flex-shrink: 1;
    align-items: flex-end;
    padding-inline: 10%;
  }

  .title {
    font-size: 32px;
    margin: 0;
    text-align: left;
    align-self: flex-start;
  }

  .text {
    width: 100%;
    max-width: 500px;
    font-size: 20px;
    text-align: left;
    align-self: flex-start;
  }

  .bigImage {
    max-width: 150px;
    margin-right: auto;
  }

  .bigImageTwo {
    max-width: 250px;
    margin-right: auto;
  }
}
