.ionToolBar {
  --background: #fff;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

.ionItem {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #81668f;
}

.current {
  font-weight: 500;
  color: #33213d;
}

.searchWrapper {
  padding-bottom: 0 !important;
  --padding-bottom: 0 !important;
}

.close {
  font-size: 24px;
  cursor: pointer;
}
