.successImage {
  width: 72px;
}

.heading {
  font-size: 14px;
  color: #33213d;
  font-weight: 600;
  margin: 24px 0;
}

.text,
.textAccent {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}

.text {
  color: #81668f;
}

.textAccent {
  font-weight: 600;
  color: #33213d;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  text-align: center;
}

.stagesWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-block: 48px;
}

.stages,
.stagesMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.stages {
  position: relative;
  z-index: 10;
  background: #fff;
}

.stagesMiddle {
  position: absolute;
  width: 100%;
}

.stage {
  font-size: 12px;
  line-height: 17px;
  color: #33213d;
  margin: 0;
}

.done {
  min-width: 24px;
}

.nextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.next,
.last {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  outline: 1px solid #61c690;
}

.line,
.lineRight,
.lineLeft {
  width: 100%;
  height: 1px;
  background: #61c690;
}

.lineLeft {
  background: transparent;
}

.lineRight {
  background: #e1dee3;
}

.last {
  outline: 1px solid #e1dee3;
}
