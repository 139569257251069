.hero {
  margin-block: 16px;
  border-radius: 4px;
  overflow: hidden;
}

.giftcards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.giftcard {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.giftcardImage {
  height: 48px;
}

.heroWrapper {
  position: relative;
}

.heroContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroTitle,
.heroText {
  margin: 0;
  color: #4d3659;
  letter-spacing: -0.02em;
}

.heroTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 2px;
}

.heroText {
  font-size: 12px;
  line-height: 16px;
}

.giftcardName {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;

  color: #33213d;
}
