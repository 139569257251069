.title {
  color: #33213d;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-block: 24px 8px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.text {
  color: #81668f;
  font-size: 14px;
  line-height: 20px;
  margin-block: 0 26px;
}
