.wrapper {
  display: flex;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  padding: max(20px, 2.91667vh) 12.63889vw;
}

.logo {
  width: 110px;
}

.formWrapper {
  padding-inline: 1.805554vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.title {
  color: #33213d;
  font-size: 32px;
  margin-block: min(9.513889vh, 10px) 0.5em;
  margin-block: 9.513889vh 0.5em;
}

.forgotPasswordWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.forgotPassword {
  margin-block: -16px 0;
  padding-block: 4px;
}

.forgotPassword,
.signUpAccent {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #7614ac;
}

.text {
  color: #81668f;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  margin-block: 0 2.5em;
}

.signUp {
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #81668f;
}

.signUpWrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper>* {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.aside {
  background-color: #fcf7ff;
  width: 42.29%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
}

.displayImage {
  width: 300px;
}

.errorMesssage,
.noErrorMesssage {
  color: brown;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.errorMesssage {
  margin-block: 10px;
}

.noErrorMesssage {
  opacity: 0;
}

.forgotPasswordWrapper,
.signUpAccent {
  cursor: pointer;
}

.swiper {
  height: auto;
}

.outerBodyWrapper {
  background-color: #fcf7ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-grow: 1; */
  padding-inline: 10%;
  margin-block: auto;
}

.bigImageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.bigImage {
  width: 100%;
  max-width: 140px;
}

.bigImageTwo {
  width: 100%;
  max-width: 255px;
}

.textTitle {
  flex-shrink: 0;
  width: 100%;
  font-size: 18px;
  color: #33213d;
  font-weight: 700;
  line-height: 1.22;
  margin: 0;
  text-align: center;
}

.textBody {
  width: 90%;
  max-width: 320px;
  margin-block: 16px 0;
  text-align: center;
  color: #81668f;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 16px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.overlay {
  position: absolute;
  top: 75%;
  right: 0;
  bottom: -40%;
  left: 0;
  background: linear-gradient(180.4deg,
      rgba(252, 247, 255, 0) 0.34%,
      #fcf7ff 12.24%);
}

.proceed {
  font-size: 14px;
  line-height: 1.5;
  color: #81668f;
  margin: 0 !important;
}

.buttonWrapper {
  margin-top: -32px;
}

.proceedAccent {
  font-weight: 500;
  color: #7614ac;
  border-bottom: 1px solid #7614ac;
  margin: 0 !important;
}

@media (max-width: 1200px) {
  .main {
    padding-inline: 8vw;
  }
}

@media (max-width: 1040px) {
  .formWrapper {
    padding: 0;
  }

  .main {
    padding-inline: 8vw;
  }

  .bodyWrapper {
    padding-inline: 15%;
  }
}

@media (max-width: 900px) {
  .title {
    margin-top: 5.513889vh;
    font-size: 24px;
  }

  .text {
    font-size: 14px;
  }

  .main {
    padding-inline: 6vw;
    max-width: 575px;
    margin-inline: auto;
  }

  .signUp,
  .forgotPassword,
  .signUpAccent {
    font-size: 14px;
  }

  .proceed {
    font-size: 12px;
  }
}

@media (min-width: 599px) and (max-height: 600px) {
  .bigImage {
    max-width: 80px;
  }

  .bigImageTwo {
    max-width: 134px;
  }
}