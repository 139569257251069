.icon {
  cursor: pointer;
  margin-inline: 6px;
  color: #81668f;
}

.input {
  font-size: 14px;
  line-height: 17px;
  color: #33213d;
}

.wrapper {
  display: flex;
  align-items: center;
}

.label {
  color: #33213d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 5px !important;
}

.formInput {
  --border-color: #f9f2fc;
  --border-width: 1px;
}

@media (min-width: 601px) {
  .input,
  .label {
    font-size: clamp(14px, 1.0725vw, 16px);
    line-height: 1.4375;
  }

  .formInput {
    font-size: clamp(14px, 1.0725vw, 16px);
    margin-top: 0.5em;
  }
}
