ion-select {
  --placeholder-opacity: 1;
  width: 100%;
  justify-content: center;
}

ion-select::part(placeholder),
ion-select::part(text) {
  flex: 0 0 auto;
}

ion-select::part(text) {
  /* color: #7614ac; */
}

ion-select::part(icon) {
  opacity: 1;
  margin-left: auto;
  color: #7614ac;
}
