.cryptoHeaderText {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  text-align: center;
  color: #81668f;
  margin: 0 0 15px 0;
}

.heading {
  font-size: 14px;
  color: #33213d;
  font-weight: 400;
  margin: 0 0 5px 0;
}

.inputWrapper {
  margin-top: 21px;
}

.bankOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}

.seeAll {
  color: #7614ac;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.seeAllWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.seeAllIcon {
  color: #7614ac;
}

.bankActions {
  margin: 0 0 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  color: #33213d;
}

.bankDetails {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bankDetailsWrapper {
  border: 1px solid #f9f2fc;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.bankLogo {
  width: 24px;
}

.accountNumber {
  font-weight: 500;
  color: #4d3659;
}

.accountName {
  font-weight: 400;
  color: #796882;
}

.accountNumber,
.accountName {
  margin: 0;
  font-size: 14px;
  line-height: 1.45;
}

.bankIcon {
  width: 15px;
}

.bankDetailsWrapperInner {
  display: flex;
  align-items: center;
  gap: 16px;
}

.accountDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.accountNumber {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d3659;
}

.accountDetailsInner {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.accountNameWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.accountName {
  font-size: 14px;
  line-height: 20px;
  color: #796882;
  text-transform: capitalize;
  margin: 0;
}

.accountNameIcon {
  color: #61c690;
  font-size: 14px;
}

.accountNameIconError {
  font-size: 14px;
  color: #e56a1f;
}

.accountNameIconLarge {
  color: #61c690;
  font-size: 24px;
}

.accountDetailsWrapper {
  border: 1px solid #f9f2fc;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.selectedbankName,
.selectedbankNameLight {
  font-size: 14px;
  line-height: 17px;
  color: #33213d;
}

.accountDetailsMain {
  display: flex;
  align-items: center;
}

.addAnother {
  margin-top: 16px;
}

.label {
  color: #33213d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 5px !important;
}

.selectedbankNameLight {
  opacity: 0.5;
}

.accountDetailsButton {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  background: transparent;
  padding: 10px;
  border: 1px solid #f9f2fc;
  border-radius: 4px;
}

.toggle {
  zoom: 0.8;
}

.defaultWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.defaultText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.bankDetailsText,
.bankDetailsHeader,
.bankDetailsHeaderSuccessful {
  margin: 0;
}

.accountNumberWrapper {
  margin-bottom: 28px;
}

.bankNameWrapper {
  margin-bottom: 8px;
}

.bankDetailsText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  margin-bottom: 24px;
}

.bankDetailsHeader,
.bankDetailsHeaderSuccessful {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #33213d;
  margin-block: 24px 16px;
}

.bankDetailsHeaderSuccessful {
  text-align: center;
}

.newAccountDetails {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: auto;
}

.newAccountNumber,
.newAccountName {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.newAccountName {
  color: #81668f;
  text-transform: capitalize;
}

.newAccountNumber {
  color: #4d3659;
  font-weight: 500;
}

.bankLogo {
  width: 24px;
  margin-right: 16px;
}

.messsageWrapper {
  margin-top: auto;
}

.newAccount {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #f9f2fc;
  border-radius: 4px;
}
