.swiper-pagination-bullet {
  width: 16px !important;
  height: 4px !important;
  border-radius: 4px !important;
  background-color: #b1a3b8 !important;
  transform: scale(1) !important;
}

.swiper-pagination-bullet-active {
  background-color: #7614ac !important;
}

.swiper-container {
  overflow: visible !important;
}

:root {
  --swiper-pagination-bullet-horizontal-gap: 2px !important;
}

.swiper-custom-pagination {
  display: flex;
  padding-top: 10px;
  justify-content: center;
}
