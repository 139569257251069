.ionToolBar {
  --background: #fff;
  /* padding-bottom: 24px; */
}

.backButton {
  display: block;
  --padding-top: 16px;
  --padding-left: 0;
  padding-left: 5px;
}

.headerTitle {
  position: absolute;
  top: 8px;
  /* transform: ; */
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
