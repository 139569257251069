.image {
  width: 110px;
  margin: 3px auto 34px;
}

.ionToolBar {
  --background: #fff;
  padding-bottom: 0;
}

.content {
  --background: #fff;
}

.footer {
  --background: #fff;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
}

.footer > * {
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
}

@media (min-width: 600px) {
  .image {
    margin-top: 20px;
  }
}
