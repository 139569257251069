.ionToolBar {
  --background: #fff;
  padding-bottom: 3px;
}

.wrapper {
  cursor: pointer;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  z-index: 200;
}

.level {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 30px;

  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #81668f;
}

.backButton {
  --padding-left: 0;
  width: 24px;
  height: 24px;
}

.headerTitle {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
