.outerWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fefcff;
  padding-bottom: 20px;
}

.logo {
  width: 110px;
  margin: 42px 12.6vw 10px;
}

.wrapper {
  padding: clamp(60px, 5.555vw, 80px);
  width: min(575px, 80%);
  border: 1px solid #f0eef1;
  background: #fff;
  align-self: center;
  margin-block: auto;
}
