.button {
  height: 54px;
  --box-shadow: none;
  cursor: pointer;
  margin-block: 0;
}

.textPrimary,
.textSecondary {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;
  letter-spacing: -0.02em;
  text-transform: none;
}

.textPrimary {
  color: #ffffff;
}

.textSecondary {
  color: #7614ac;
}

.textDisabled {
  color: #c9c1cd;
}

.spinner {
  margin-left: 10px;
}

.submitButton {
  display: none;
}
