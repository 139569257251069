.profile {
  height: 48px;
}

.wave {
  height: 15px;
}

.ionToolBar {
  --background: #fff;
}

.profileWrapper {
  display: flex;
  gap: 8px;
}

.secondHeadingNotAvailable {
  font-size: 14px;
  color: #cb5531;
  font-weight: 600;
  padding: 20px;
  margin: 0;
  text-align: center;
}

.greetingsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.greetingsTop {
  display: flex;
  align-items: center;
  gap: 4px;
}

.today {
  font-size: 14px;
  line-height: 1.42857;
  color: #81668f;
  margin: 0;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2px !important;
  --padding-bottom: 0 !important;
}

.title {
  position: static;
  padding-inline: 0;
  padding: 0;
}

.notificationIcon {
  height: 17px;
}

.notificationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f9f8fa;
  position: relative;
}

.notificationWrapper::before {
  content: "";
  width: 8px;
  height: 8px;
  background: #d95c1e;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%);
}

.cardContainer {
  position: relative;
  background: #f9f2fc;
  height: 164px;
  border-radius: 4px;
  overflow: hidden;
  padding: 24px;
}

.line {
  width: 100%;
  height: 1px;
  background: #f9f2fc;
  margin-block: 24px;
}

.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.cardHeader {
  font-size: 10px;
  color: #81668f;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  margin: 0;
}

.cardBalance {
  color: #7614ac;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.cardOptions {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.cardOptions > * {
  flex-basis: 0;
  flex-grow: 1;
  width: 50%;
}

.cardOPtionsButton {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #81668f;
  background: var(--ion-background-color);
  border-radius: 30px;
  margin: 0;
  text-transform: capitalize;
}

.cardHeaderWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  cursor: pointer;
  margin-inline: 6px;
  color: #81668f;
}

.giftCardHeader,
.cryptoHeader,
.billsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.billsHeader {
  justify-content: flex-start;
  gap: 8px;
}

.giftcardIcon {
  height: 24px;
}

.giftCardHeaderLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerTitle,
.seeAll {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #33213d;
  margin: 0;
}

.seeAll {
  color: #7614ac;
}

.seeAllWrapper,
.seeAllRatesWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.seeAllRatesWrapper {
  margin-top: 20px;
  padding-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seeAllIcon,
.howToSellIcon {
  color: #7614ac;
}

.howToSellIcon {
  transform: rotate(-180deg);
}

.optionsIcon {
  min-width: 12px;
  max-width: 12px;
  color: #81668f;
  --ionicon-stroke-width: 50px;
}

.giftcards,
.bills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6.5em, 1fr));
  gap: 8px;
}

.giftcard,
.bill {
  padding: 16px 8px;
  background: #f8f7f8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 250px;
  min-height: 105px;
  cursor: pointer;
}

.billName {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #81668f;
  margin: 0;
  text-align: center;
}

.giftcardImage,
.billImage {
  height: 48px;
}

.heroImage {
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
}

.heroImageWrapper {
  border-radius: 5px;
  overflow: hidden;
}

.giftcardDetailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media (max-width: 400px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .today {
    font-size: 12px;
  }

  .greetingsWrapper {
    justify-content: flex-start;
  }
}
