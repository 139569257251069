.header {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  margin: 0;
}

.linkAccount {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-block: 16px;
  cursor: pointer;
  padding-bottom: 16px;
}

.linkAccountIcon {
  color: #81668f;
  font-size: 24px;
}

.ionToolBar {
  --background: #fff;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

.selectBank {
  border: 1px solid #f9f2fc;
  overflow: hidden;
  --inner-border-width: 0;
}

.accountName {
  font-size: 12px;
  line-height: 15px;
  color: #33213d;
  text-transform: capitalize;
}

.donwArrow {
  width: 20px;
  color: #81668f;
}

.bankIcon {
  width: 72px;
}

.close {
  font-size: 24px;
  cursor: pointer;
}

.linkAccountText {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #81668f;
}

.bankIconWrapper,
.bankIconWrapperSuccessful {
  display: flex;
}

.bankIconWrapperSuccessful {
  justify-content: center;
}

.selectBankFocus {
  border: 2px solid #7614ac;
  border-radius: 4px;
}

.noAccount {
  margin: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #33213d;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.message {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #c75611;
  padding: 16px;
  border-radius: 4px;
}

.disable {
  display: none;
}

.visible {
  display: block;
}

.accountDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.accountNumber {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d3659;
}

.accountDetailsInner {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.accountNameWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.accountName {
  font-size: 14px;
  line-height: 20px;
  color: #796882;
  text-transform: capitalize;
  margin: 0;
}

.accountNameIcon {
  color: #61c690;
  font-size: 14px;
}

.accountNameIconError {
  font-size: 14px;
  color: #e56a1f;
}

.accountNameIconLarge {
  color: #61c690;
  font-size: 24px;
}

.accountDetailsWrapper {
  border-bottom: 1px solid #f9f2fc;
  padding-block: 24px;
}

.selectedbankName,
.selectedbankNameLight {
  font-size: 14px;
  line-height: 17px;
  color: #33213d;
}

.accountDetailsMain {
  display: flex;
  align-items: center;
}

.addAnother {
  margin-top: 16px;
}

.label {
  color: #33213d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 5px !important;
}

.selectedbankNameLight {
  opacity: 0.5;
}

.accountDetailsButton {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  background: transparent;
  padding: 10px;
  border: 1px solid #f9f2fc;
  border-radius: 4px;
}

.toggle {
  zoom: 0.8;
}

.defaultWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.defaultText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.bankDetailsText,
.bankDetailsHeader,
.bankDetailsHeaderSuccessful {
  margin: 0;
}

.accountNumberWrapper {
  margin-bottom: 28px;
}

.bankNameWrapper {
  margin-bottom: 8px;
}

.bankDetailsText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  margin-bottom: 24px;
}

.bankDetailsHeader,
.bankDetailsHeaderSuccessful {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #33213d;
  margin-block: 24px 16px;
}

.bankDetailsHeaderSuccessful {
  text-align: center;
}

.newAccountDetails {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-right: auto;
}

.newAccountNumber,
.newAccountName {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.newAccountName {
  color: #81668f;
  text-transform: capitalize;
}

.newAccountNumber {
  color: #4d3659;
  font-weight: 500;
}

.bankLogo {
  width: 24px;
  margin-right: 16px;
}

.messsageWrapper {
  margin-top: auto;
}

.newAccount {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #f9f2fc;
  border-radius: 4px;
}

.unlinkAlert.alert-message {
  color: red;
}

.unlinkAlert .confirmAlert {
  color: #f03341;
}

.unlinkAlert .cancelAlert {
  color: #007aff;
}

.unlinkAlert.sc-ion-alert-ios-h {
  --background: rgba(242, 242, 242, 0.8);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.63);
}
