.giftcardImage {
  width: 63px;
}

.topWrapper {
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  margin-top: 8px;
}

.giftcardName {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #33213d;
}

.seeAll {
  color: #7614ac;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.seeAllWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.noAccount {
  margin: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #33213d;
}

.selectBankWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 24px 14px;
}

.selectBankWrapper .heading {
  margin: 0;
}

.seeAllIcon {
  color: #7614ac;
}

.cardInfoType {
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  color: #81668f;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 16px;
}

.flag {
  width: 100%;
}

.countryInfo {
  display: flex;
  align-items: center;
}

.description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #81668f;
}

.heading,
.secondHeading {
  font-size: 14px;
  color: #33213d;
  font-weight: 600;
  margin: 24px 0 14px;
}

.secondHeading {
  margin: 0;
}

.line {
  height: 1px;
  background: #f9f2fc;
  margin-top: 24px;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 24px 14px;
}

.whatIsThis {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #81668f;
}

.list {
  padding: 0;
}

.current {
  font-weight: 500;
  color: #33213d;
}

.countryInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.countryCode {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #81668f;
  margin: 0;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 100%;
}

.ionSegmentButton {
  width: 50%;
}

.typeDetails {
  font-size: 14px;
  line-height: 20px;
  margin: 24px 0;
  color: #81668f;
}

.cardImage {
  width: 90%;
  max-width: 171px;
  margin-inline: auto;
}

.ionToolBar {
  --background: #fff;
  margin-block: 8px;
}

.content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.rateWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ionItem {
  --padding-start: 0;
  --inner-padding-end: 0;
  border-block: 1px solid #f9f2fc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.ionSelectWrapper {
  border: none;
}

.actionWrapper {
  width: 33.333%;
}

.rateHeader,
.rateBody,
.count {
  margin: 0;
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.rateBody,
.rateHeader {
  width: 33.333%;
}

.count,
.rateBody {
  margin-top: 0;
  color: #33213d;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 90px;
}

.rateContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.accountDetails {
  font-size: 10px;
}

.description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #81668f;
  /* margin-right: 20px; */
  width: 100%;
  text-transform: capitalize;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f2fc;
  border-radius: 4px;
  padding: 13px 16px;
  margin-block: 10px;
  gap: 10px;
}

.amountText,
.amountPrice {
  margin: 0;
}

.amountText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.amountPrice {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #7614ac;
}
