.footer {
  --background: #fff;
  --padding-start: 0;
  --padding-end: 0;
}

.errorMesssage,
.noErrorMesssage {
  color: brown;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.errorMesssage {
  margin-block: 10px;
}

.noErrorMesssage {
  opacity: 0;
}
