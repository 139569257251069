.tabBar {
  --background: #fff;
  box-shadow: 0px -1px 4px rgba(187, 187, 187, 0.25);
  padding: 5px;
}

.tabBarIcon {
  width: 24px;
  margin-bottom: 2px;
}

.label {
  font-size: 10px;
}
.videoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-inline: auto;
  height: 100%;
  max-height: 100vh;
  padding-inline: 20px;
}

.videoDisplayWrapper {
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.video {
  height: 90%;
  aspect-ratio: 2.16216/1;
  /* aspect-ratio: 1/2.16216; */
}

.title {
  margin-block: 24px 16px;
  text-align: center;
  color: rgb(53, 27, 70);
}

.headerAccent {
  text-transform: capitalize;
}
