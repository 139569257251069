.ionToolBar {
  --background: #fff;
}

.headerTitle {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.profileWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile {
  height: 76px;
}

.errorMesssage,
.noErrorMesssage {
  color: brown;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.errorMesssage {
  margin-block: 10px;
}

.noErrorMesssage {
  opacity: 0;
}

.editButton {
  margin-block: 16px 24px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.ionToolBar {
  --background: #fff;
  margin-block: 12px;
}

.content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.deleteWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
}

.typeDetails {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 40px;
  color: #81668f;
  text-align: center;
}

.greetingsWrapper {
  display: flex;
  flex-direction: column;
}

.editButtonWrapper {
  border-bottom: 1px solid #f9f2fc;
}

.userName,
.email {
  margin: 0;
}

.textArea {
  max-height: 130px;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-opacity: 0.5;
  border: 1px solid rgb(118, 20, 172);
  border-radius: 4px;
  margin-bottom: 24px;
}

.deleteButtonWrapper {
  margin-top: auto;
}

.userName {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #372c3d;
  text-transform: capitalize;
}

.email {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
}

.title,
.header,
.text {
  margin: 0;
}

.title {
  color: #9884a4;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 6px;
}

.header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #33213d;
}

.text {
  font-size: 12px;
  line-height: 17px;
  color: #9884a4;
}

.settingsIcon {
  width: 32px;
  margin-right: 16px;
}

.settingsTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.settingsWrapper {
  margin-top: 24px;
}

.tawtTo {
  position: fixed;
  top: 70px;
  min-height: 100vh;
  left: 0;
  width: 100vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.tawtToFrame {
  display: block;
  width: 100%;
  height: 100%;
  height: 75vh;
}

.tawtToClose {
  position: fixed;
  top: 85px;
  bottom: 0;
  right: 30px;
  font-size: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 100;
  width: 40px;
  aspect-ratio: 1/1;
}

.settingsItem {
  display: flex;
  align-items: center;
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px;
  --inner-border-width: 0 0 1px 0;
  --border-color: #f9f2fc;
}

.settingsItemBottom {
  margin-top: 14px;
}

.settingsItemNoLine {
  --inner-border-width: 0;
}

.logoutAlert.alert-message {
  color: red;
}

.logoutAlert .confirmAlert {
  color: #f03341;
}

.logoutAlert .cancelAlert {
  color: #007aff;
}

.logoutAlert.sc-ion-alert-ios-h {
  --background: rgba(242, 242, 242, 0.8);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.63);
}
