.videoWrapper {
  border-left: 1px solid #f4f4f4;
  font-size: clamp(28px, 2.145vw, 32px);
  padding: 1.75em;
}

@media (max-width: 700px) {
  .videoWrapper {
    padding: 18px;
  }
}

.videoDisplayWrapper {
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.header {
  font-size: clamp(28px, 2.145vw, 32px);
  font-weight: 700;
  line-height: 1.03125;
  letter-spacing: -0.02em;
  margin-block: 0 1.75em;
}

.video {
  height: 80vh;
  aspect-ratio: 2.16216/1;
}

.headerAccent {
  text-transform: capitalize;
}
