.mailLink {
  display: block;
  width: 100% !important;
  text-decoration: none !important;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 100%;
  max-width: 61px;
  height: 57px;
  border: 1px solid #e1dee3;
  border-radius: 4px;
  outline: none;
}

.image {
  width: 90%;
  max-width: 137px;
}

.resend {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
  text-align: center;
  text-decoration: none;
  margin-block: 45px 0;
}

.resendAccent {
  font-weight: 600;
  color: #7614ac;
}

.title {
  color: #33213d;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-block: 48px 16px;
}

.text {
  color: #81668f;
  font-size: 14px;
  line-height: 20px;
  margin-block: 0 4px;
  text-align: center;
}

.textAccent {
  font-weight: 500;
  color: #7614ac;
  margin-bottom: 48px;
}
