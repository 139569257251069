.stagesWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 18px 0;
}

.stages,
.stagesMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.stages {
  position: relative;
  z-index: 10;
  background: #fcfbfc;
}

.stagesMiddle {
  position: absolute;
  width: 100%;
}

.stage {
  font-size: 14px;
  line-height: 17px;
  color: #33213d;
  margin: 0;
  text-align: center;
}

.done {
  min-width: 24px;
}

.nextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.next {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  outline: 1px solid #61c690;
}

.line,
.lineLeft,
.lineGray {
  width: 100%;
  height: 1px;
  background: #61c690;
}

.lineGray {
  background: #e1dee3;
}

.lineLeft {
  background: transparent;
}

.statusWrapper {
  padding: 16px;
  border-radius: 6px;
  color: #af4646;
  background: rgba(198, 97, 97, 0.1);
  margin-block: 24px;
}

.statusWrapperPending {
  color: #884116;
  background: #fde6d8;
}

.statusWrapperSuccess {
  background: rgba(97, 198, 144, 0.1);
  color: #2a965c;
}

.statusHeader {
  margin: 0 0 0.44em 0;
  font-weight: 600;
  font-size: clamp(14px, 1.2065vw, 18px);
  line-height: 1.45;
  color: inherit;
}

.statusBody {
  margin: 0;
  font-weight: 400;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.45;
  color: inherit;
}

.tradeDetailsWrapper {
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
}

.giftcardImage {
  width: 74px;
}

.cryptoNameAndSymbol {
  margin-block: 12px 3px;
  font-weight: 500;
  font-size: clamp(14px, 1.2065vw, 18px);
  line-height: 1.45;
  color: #33213d;
}

.cryptoCategory {
  font-weight: 400;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.45;
  margin-block: 0 7px;
  color: #81668f;
}

.cryptoAmount {
  margin-block: 0 1.125em;
  font-weight: 700;
  font-size: clamp(20px, 1.609vw, 24px);
  line-height: 1.25;
  color: #33213d;
}

.topWrapper {
  font-size: clamp(14px, 1.2065vw, 18px);
  border: 1px solid #f9f2fc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  align-items: center;
  padding: 1.3889em 1.83em;
  gap: 1.33em;
  margin: 0;
}

.giftcardName {
  margin: 0;
  font-size: clamp(14px, 1.2065vw, 18px);
  font-weight: 500;
  line-height: 1.44;
  color: #33213d;
}

.cardInfoType {
  margin: 0;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  color: #81668f;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 100%;
}

.countryInfo {
  display: flex;
  align-items: center;
}

.description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #81668f;
  margin-right: 10px;
  text-transform: capitalize;
}

.heading,
.secondHeading {
  font-size: 14px;
  color: #33213d;
  font-weight: 600;
  margin: 24px 0;
}

.secondHeading {
  margin: 0;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 24px;
}

.whatIsThis {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #81668f;
}

.list {
  padding: 0;
}

.current {
  font-weight: 500;
  color: #33213d;
}

.countryInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.countryCode {
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  font-weight: 500;
  color: #81668f;
  margin: 0;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 100%;
}

.ionSegmentButton {
  width: 50%;
}

.typeDetails {
  font-size: 14px;
  line-height: 20px;
  margin: 24px 0;
  color: #81668f;
}

.cardImage {
  width: 90%;
  max-width: 171px;
  margin-inline: auto;
}

.ionToolBar {
  --background: #fff;
  margin-block: 8px;
}

.content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.rateWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionWrapper {
  width: 33.333%;
}

.rateHeader,
.rateBody,
.count {
  margin: 0;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  color: #81668f;
}

.rateBody,
.rateHeader {
  width: 33.333%;
  text-align: center;
}

.rateBody {
  font-size: clamp(14px, 1.2065vw, 18px);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count,
.rateBody {
  margin-top: 0;
  color: #33213d;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 90px;
}

.rateContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 24px;
}

.accountDetails {
  font-size: 10px;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border-top: 1px solid #f9f2fc;
  padding-top: 16px;
  margin-top: 16px;
}

.rateMainWrapper {
  padding: 0 16px 16px;
  border: 1px solid #f9f2fc;
  border-top: none;
}

.amountText,
.amountPrice {
  margin: 0;
}

.amountText {
  font-size: clamp(14px, 1.2065vw, 18px);
  line-height: 1.44;
  color: #81668f;
}

.amountPrice {
  font-size: clamp(20px, 1.609vw, 24px);
  line-height: 1.25;
  font-weight: 700;
  color: #7614ac;
}

.topWrapperOuter {
  background: #fff;
  padding: 24px 2.33em;
}

.ionContent {
  background: #fcfbfc;
}

.detailsWrapper {
  border-top: 1px solid #f9f2fc;
  padding: 32px 0;
}

.bottomDetails,
.bottomDetailsPayment {
  font-weight: 600;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  color: #4d3659;
  margin-bottom: 1.5em;
}

.bankLogo {
  font-size: clamp(12px, 1.0725vw, 16px);
  width: 56px;
  margin-right: 2em;
}

.bottomDetailsPayment {
  padding-block: 32px 24px;
  border-top: 1px solid #f9f2fc;
  margin-bottom: 0;
}

.bottomDetailsTitle {
  font-weight: 500;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  color: #b1a3b8;
  margin: 0;
  flex-shrink: 0;
  min-width: 82px;
}

.bottomDetailsText {
  font-weight: 500;
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  color: #4d3659;
  margin: 0;
}

.bottomInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bottomInfoWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.bankDetails {
  font-size: clamp(12px, 1.0725vw, 16px);
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  padding: 2em;
  display: flex;
  align-items: center;
}

.bankAccountNumber {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.45;
  margin: 0;
  color: #33213d;
}

.bankAccountNameBottom {
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.4375;
  font-weight: 400;
  color: #81668f;
  margin-block: 0.214em 0;
}

.header {
  padding-bottom: 12px;
}

.statusHeaderMT {
  margin-top: 10px;
  text-transform: capitalize;
}

.cryproTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasonText {
  font-weight: 400;
  margin: 0;
  display: inline;
}

/*  */

.headerWrapper {
  font-size: clamp(18px, 1.609vw, 24px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1.66em 2.33em;
  background: #fff;
}

.bodyWrapper {
  padding-inline: 2.33em;
}

.iosClose {
  width: clamp(28px, 2.145vw, 32px);
  margin: 0;
  cursor: pointer;
}

.headerTitle,
.headerBody {
  margin: 0;
}

.headerTitle {
  font-size: clamp(20px, 1.609vw, 24px);
  font-weight: 700;
  line-height: 1.25;
  color: #33213d;
  margin-bottom: 0.33em;
}

.headerBody {
  font-size: clamp(14px, 1.2065vw, 18px);
  font-weight: 400;
  line-height: 1.44;
  color: #81668f;
}

.headerTop {
  text-align: center;
  margin-inline: auto;
}

.footerWrapper {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding-bottom: 18px;
  box-shadow: 0px 0px 13px 0px #00000014;
  z-index: 50;
  background: #fff;
}

.mainWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #fcfbfc;
}

.bottomWrapper {
  padding: 24px 2.33em;
  background: #fff;
  margin-top: -24px;
}

@media (max-width: 1120px) {
  .bodyWrapper,
  .headerWrapper,
  .topWrapperOuter,
  .bottomWrapper {
    padding-inline: 1em;
  }
}
