@media (min-width: 601px) {
  .alert-wrapper {
    margin-inline: auto max(9.5vw, 50px);
    width: max(19vw, 200px);
  }

  .modal-sheet {
    justify-content: flex-end;
    --width: max(38vw, 400px);
  }
}
