

.title {
  color: #33213d;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  margin-bottom: 8px;
}

.text {
  color: #81668f;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 48px;
}
