.stagesWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 18px 0;
}

.stages,
.stagesMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.stages {
  position: relative;
  z-index: 10;
  background: #fcfbfc;
}

.stagesMiddle {
  position: absolute;
  width: 100%;
}

.stage {
  font-size: 12px;
  line-height: 17px;
  color: #33213d;
  margin: 0;
}

.done {
  min-width: 24px;
}

.nextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.next {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  outline: 1px solid #61c690;
}

.line,
.lineLeft,
.lineGray {
  width: 100%;
  height: 1px;
  background: #61c690;
}

.lineGray {
  background: #e1dee3;
}

.lineLeft {
  background: transparent;
}

.statusWrapper {
  padding: 16px;
  border-radius: 6px;
  color: #af4646;
  background: rgba(198, 97, 97, 0.1);
}

.statusWrapperPending {
  color: #884116;
  background: #fde6d8;
}

.statusWrapperSuccess {
  background: rgba(97, 198, 144, 0.1);
  color: #2a965c;
}

.statusHeader {
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.45;
  color: inherit;
}

.statusBody {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  color: inherit;
}

.tradeDetailsWrapper {
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
}

.giftcardImage {
  width: 63px;
}

.cryptoNameAndSymbol {
  margin-block: 12px 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.45;
  color: #33213d;
}

.cryptoCategory {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.45;
  margin-block: 0 7px;
  color: #81668f;
}

.cryptoAmount {
  margin-block: 0 27px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  color: #33213d;
}

.topWrapper {
  border: 1px solid #f9f2fc;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.giftcardName {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #33213d;
}

.cardInfoType {
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  color: #81668f;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 16px;
}

.flag {
  width: 100%;
}

.countryInfo {
  display: flex;
  align-items: center;
}

.description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #81668f;
  margin-right: 10px;
  text-transform: capitalize;
}

.heading,
.secondHeading {
  font-size: 14px;
  color: #33213d;
  font-weight: 600;
  margin: 24px 0;
}

.secondHeading {
  margin: 0;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 24px;
}

.whatIsThis {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #81668f;
}

.list {
  padding: 0;
}

.current {
  font-weight: 500;
  color: #33213d;
}

.countryInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.countryCode {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #81668f;
  margin: 0;
}

.flagWrapper {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  width: 100%;
}

.ionSegmentButton {
  width: 50%;
}

.typeDetails {
  font-size: 14px;
  line-height: 20px;
  margin: 24px 0;
  color: #81668f;
}

.cardImage {
  width: 90%;
  max-width: 171px;
  margin-inline: auto;
}

.ionToolBar {
  --background: #fff;
  margin-block: 8px;
}

.content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.rateWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionWrapper {
  width: 33.333%;
}

.rateHeader,
.rateBody,
.count {
  margin: 0;
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.rateBody,
.rateHeader {
  width: 33.333%;
}

.count,
.rateBody {
  margin-top: 0;
  color: #33213d;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 90px;
}

.rateContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-top: 1px solid #f9f2fc;
}

.accountDetails {
  font-size: 10px;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border-top: 1px solid #f9f2fc;
  padding-top: 16px;
  margin-top: 16px;
}

.rateMainWrapper {
  padding: 0 16px 16px;
  border: 1px solid #f9f2fc;
  border-top: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.amountText,
.amountPrice {
  margin: 0;
}

.amountText {
  font-size: 14px;
  line-height: 20px;
  color: #81668f;
}

.amountPrice {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #7614ac;
}

.topWrapperOuter {
  background: #fff;
  padding: 24px 16px 0;
  border-radius: 30px 30px 0px 0px;
}

.ionContent {
  background: #fcfbfc;
}

.detailsWrapper {
  padding: 16px;
  border-top: 1px solid #f9f2fc;
}

.detailsWrapperBorder {
  border-block: 1px solid #f9f2fc;
}

.bottomDetails,
.bottomDetailsPayment {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.45;
  color: #4d3659;
  margin-bottom: 16px;
}

.bankLogo {
  width: 24px;
  margin-right: 16px;
}

.bottomDetailsPayment {
  margin-block: 16px;
}

.bottomDetailsTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.45;
  color: #b1a3b8;
  margin: 0;
  flex-shrink: 0;
  min-width: 82px;
}

.bottomDetailsText {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.45;
  color: #4d3659;
  margin: 0;
}

.bottomInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bottomInfoWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.bankDetails {
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.bankAccountNumber {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.45;
  margin: 0;
  color: #33213d;
}

.bankAccountNameBottom {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.45;
  color: #81668f;
  margin-block: 3px 0;
}

.header {
  padding-bottom: 12px;
}

.statusHeaderMT {
  margin-top: 10px;
  text-transform: capitalize;
}

.cryproTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reasonText {
  font-weight: 400;
  margin: 0;
  display: inline;
}
