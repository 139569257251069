.title {
  font-size: clamp(24px, 2.223vw, 32px);
  font-weight: 700;
  line-height: 0.75;
  color: #33213d;
  margin: 0;
}

.body,
.email,
.resend {
  font-size: clamp(14px, 1.1115vw, 16px);
  line-height: 1.4375;
}

.body {
  font-weight: 400;
  color: #81668f;
  margin-block: 1em;
}

.email {
  font-weight: 500;
  color: #7614ac;
  margin-block: 0 2.5em;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 0;
}

.input {
  width: 100% !important;
  max-width: 80.11px !important;
  aspect-ratio: 1.11/1;
  background: transparent !important;
  outline: 1px solid #e1dee3 !important;
  border: none !important;
  border-radius: 4px !important;
}

.resend {
  display: block;
  font-weight: 400;
  color: #81668f;
  margin-block: 1.5em 1.375em;
}

.resendAccent {
  font-weight: 600;
  color: #7614ac;
}

.input:focus,
.input:active {
  border: none !important;
  outline: 1px solid #7614ac !important;
}
