.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 99999;
  background: #00000055;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.wrapper {
  position: relative;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 10px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  animation: popup 400ms ease-in-out forwards;
}

.message {
  margin-block: 10px;
  font-size: 15px;
  color: rgba(53, 27, 70, 0.765);
}

.congratsWrapper {
  height: 100px;
}

.congrats {
  height: 100%;
  animation: popup 400ms ease-in-out forwards;
}

.title {
  color: rgb(53, 27, 70);
}

@keyframes popup {
  0% {
    transform: scale(0);
  }

  70% {
    transform: scale(1);
  }

  85% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

.closeIcon {
  position: absolute;
  top: -36px;
  right: 0;
  width: 20px;
  height: 20px;
  background: #0000004a;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
  cursor: pointer;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.button,
.buttonSecond {
  padding: 15px 10px;
  border-radius: 8px;
  border-radius: 50px;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;
  letter-spacing: -0.02em;
}

.button {
  background-color: rgb(139, 54, 196);
  color: #fff;
}

.buttonSecond {
  background-color: rgb(234, 221, 238);
  color: rgb(118, 20, 172);
}
