.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-block: 0 22px;
}

.title {
  font-size: clamp(24px, 2.223vw, 32px);
  font-weight: 700;
  line-height: 0.75;
  color: #33213d;
  margin: 0;
}

.body {
  font-size: clamp(14px, 1.1115vw, 16px);
  line-height: 1.4375;
  font-weight: 400;
  color: #81668f;
  margin-block: 1em;
}
