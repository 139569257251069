.successImg {
  width: 90%;
  max-width: 78px;
  /* margin-top: 192px; */
  margin-top: 18.23vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #33213d;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding-inline: 20px;
  margin-block: 28px 16px;
  text-align: center;
}

.text {
  color: #81668f;
  padding-inline: 10px;
  font-size: 14px;
  line-height: 20px;
  margin-block: 0 26px;
  text-align: center;
}
