.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  /* bottom: 0; */
  left: 0;
  overflow: auto;
  /* max-width: 500px; */
  /* margin-inline: auto; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --ion-background-color: #fff;
  /* padding-bottom: 20px; */
}

.welcomeWrapper {
  max-width: 100%;
}
