.wrapper {
  border-left: 1px solid #f4f4f4;
  font-size: clamp(28px, 2.145vw, 32px);
  padding: 1.75em;
}

.wave {
  width: 24px;
}

.welcomText {
  display: flex;
  align-items: center;
  gap: 0.33em;
  font-size: clamp(20px, 1.609vw, 24px);
  font-weight: 400;
  line-height: 0.75;
  color: #81668f;
  margin-block: 0 2.33em;
}

.header {
  font-size: clamp(28px, 2.145vw, 32px);
  font-weight: 700;
  line-height: 1.03125;
  letter-spacing: -0.02em;
  margin-block: 0 0.46875em;
}

.sectionsWrapper {
  font-size: clamp(20px, 1.609vw, 24px);
  display: flex;
  align-items: flex-start;
  gap: 2.33em;
  margin-bottom: 2.33em;
}

.section {
  font-size: clamp(20px, 1.609vw, 24px);
  border: 1px solid #f2f2f2;
  padding: 1.33em;
  border-radius: 4px;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 45%;
}

.top {
  font-size: clamp(20px, 1.609vw, 24px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.topHeader {
  font-size: clamp(20px, 1.609vw, 24px);
  font-weight: 600;
  line-height: 1.208;
  color: #33213d;
  margin: 0;
}

.seeAll {
  font-size: clamp(14px, 1.2065vw, 18px);
  line-height: 1.22;
  font-weight: 600;
  color: #7614ac;
  margin: 0;
  cursor: pointer;
}

/*  */

.cardContainer {
  font-size: clamp(28px, 2.145vw, 32px);
  position: relative;
  background: #f9f2fc;
  border-radius: 4px;
  overflow: hidden;
  padding: 1.25em 0.9375em;
  margin-bottom: 0.5em;
}

.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.cardDetails {
  display: flex;
  flex-direction: column;
}

.cardHeader {
  font-size: 14px;
  color: #81668f;
  font-weight: 600;
  line-height: 1.214;
  text-transform: uppercase;
  margin: 0;
}

.transactionDetailsWrapper {
  margin-right: 10px;
}

.cardBalance {
  color: #7614ac;
  font-size: clamp(28px, 2.145vw, 32px);
  font-weight: 700;
  line-height: 1.25;
  margin-block: 1em 1.25em;
}

.cardOptions {
  font-size: clamp(14px, 1.0725vw, 16px);
  display: flex;
  gap: 1em;
  justify-content: center;
  flex-wrap: wrap;
}

.cardOptions > * {
  flex-basis: 0;
  flex-grow: 1;
  width: 50%;
}

.cardOPtionsButton {
  font-size: clamp(14px, 1.0725vw, 16px);
  line-height: 1.1875;
  font-weight: 600;
  color: #81668f;
  background: var(--ion-background-color);
  border-radius: 1.875em;
  margin: 0;
  text-transform: capitalize;
}

.cardHeaderWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  cursor: pointer;
  margin-inline: 6px;
  color: #81668f;
  width: 16px;
}

.optionsIcon {
  min-width: 12px;
  max-width: 12px;
  color: #81668f;
  --ionicon-stroke-width: 50px;
}

.accountNumber {
  border: 1px solid #f9f2fc;
  border-radius: 4px;
  padding: 14px;
}

.accountHeading {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42857;
  color: #33213d;
  margin: 0;
  margin-bottom: 2px;
}

.accountText {
  font-size: 12px;
  line-height: 1.4167;
  color: #81668f;
  margin: 0;
}

.tradeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #f9f2fc;
}

.transactionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.noTransactions {
  margin: 0;
  padding: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #33213d;
}

.transaction {
  display: flex;
  align-items: center;

  padding-block: 10px;
  margin-inline: 10px;
  cursor: pointer;
}

.allTransactionsWrapper:nth-child(odd) {
  border-block: 1px solid #f9f2fc;
}

.transactionAmount,
.transactionCategory,
.transactionType {
  margin: 0;
}

.transactionAmount {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #33213d;
}

.transactionTime {
  text-transform: capitalize;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #9884a4;
}

.transactionCategory,
.transactionType {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #674e74;
}

.transactionCategory {
  margin-block: 2px;
}

.transactionType {
  font-weight: 400;
  color: #b1a3b8;
}

.transactionStatus {
  margin-left: auto;
}

.transactionImage {
  width: 63px;
  margin-right: 16px;
}

.cryptoImage {
  width: 24px;
}

.cryptoHistoryWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-block: 10px;
  margin-inline: 10px;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid #f9f2fc;
}

.cryptoCode {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cryptoValueHeader,
.cryptoValueBody {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45;
  color: #33213d;
}

.cryptoValueBody {
  color: #b1a3b8;
}

.cryptoCodeLast {
  margin-left: auto;
  padding-inline: 5px;
}

.cryptoSuccess {
  color: #61c690;
}

.cryptoPending {
  color: #e56a1f;
}

.cryptoFailed {
  color: #ed4c49;
}

.giftcards,
.bills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6.5em, 1fr));
  gap: 1.25em;
  font-size: clamp(28px, 2.145vw, 32px);
}

.giftcard,
.bill {
  padding: 24px;
  background: #f8f7f8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.giftcardImage,
.billImage {
  height: 48px;
}

.secondHeadingNotAvailable {
  font-size: 14px;
  color: #cb5531;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.billName {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #81668f;
  margin: 0;
  text-align: center;
}

.cryptoSection {
  margin-top: 33px;
}

@media (max-width: 1180px) {
  .sectionsWrapper {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 700px) {
  .wrapper {
    padding: 18px;
  }
}
